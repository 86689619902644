import React from "react";
import Image from "next/image";
import {styled, tooltipClasses, TooltipProps} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import i18next from "i18next";
import translationParser from "../../../services/translation/translationParser";

const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export default function ProductBadgePharma({product, listing = false}: any) {
    if (!product.isPharma) return <></>

    if (listing) {
        return (
            <div className={"product-badge me-2 d-flex flex-row justify-content-start align-items-start"}>
                <Image src={`/images/PFH-Icon-Dermatologen-V5-schwarz.svg`}
                       height={25}
                       width={25}
                       alt={"Pharma"}
                       style={{maxHeight: "40px"}}
                       className={"h-100 w-auto"}/>
            </div>
        )
    }

    return (
        <div className={"d-flex gap-2 w-100 align-items-center pt-2 pb-2 rounded-2 justify-content-center ps-2 pe-2"}
             style={{backgroundColor: '#f8e6d5'}}>
            <Image src={`/images/PFH-Icon-Dermatologen-V5-schwarz.svg`} className={"ms-2 ps-2 pe-2"} height={25} width={25} alt={"Pharma"}/>
            <span className={"fw-bold text-center"} style={{fontSize: '0.8rem'}}>
                {i18next.t('pharma.logoText').toString()}
            </span>
            {product.pharmacode && (
                <LightTooltip arrow title={`${i18next.t('pharma.code')} ${product.pharmacode}`}>
                    <i className="fa-sharp fa-solid fa-circle-info ms-2"/>
                </LightTooltip>
            )}
            <LightTooltip arrow title={(
                <p>{i18next.t('pharma.info').toString()}</p>
            )}>
                <i className="fa-solid fa-circle-question ms-2"></i>
            </LightTooltip>
        </div>
    )
}