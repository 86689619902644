import {CookieValueTypes, deleteCookie, getCookie, getCookies, setCookie} from "cookies-next";
import cookieOptions from "./cookieOptions";

class ApiClient {
    private storageKeys: string[] = ['sessionId', 'wishlist', 'keepLogin', 'customer', 'cemv', 'cems', 'keepLogin'];
    private clearableCookies: string[] = ['sessionId', 'wishlist', 'keepLogin', 'customer', 'keepLogin', 'sanctumToken', 'XSRF-TOKEN', 'perfecthairch_session'];

    async post(path: string, data?: any): Promise<any> {
        try {
            const res: Response = await fetch(
                `${process.env.NEXT_PUBLIC_API_URL}${path}`, {
                    method: 'POST',
                    credentials: "include",
                    headers: {
                        'Access-Control-Allow-Headers': '*',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.getToken()}`,
                    },
                    body: this.parseBody(data),
                    next: {revalidate: 0}
                });

            const responseData: any = await res.json();
            this.setStorage(responseData);
            return {data: responseData, status: res.status};
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async get(path: string): Promise<any> {
        try {
            const res: Response = await fetch(
                `${process.env.NEXT_PUBLIC_API_URL}${path}`, {
                    method: 'GET',
                    credentials: "include",
                    headers: {
                        'Access-Control-Allow-Headers': '*',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.getToken()}`,
                    },
                    next: {revalidate: 0}
                });

            const responseData: any = await res.json();
            this.setStorage(responseData);
            return {data: responseData, status: res.status};
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async csrf(): Promise<any> {
        try {
            const res: Response = await fetch(
                `${process.env.NEXT_PUBLIC_API_URL}/sanctum/csrf-cookie`, {
                    method: 'GET',
                    credentials: "include",
                    headers: {
                        'Access-Control-Allow-Headers': '*',
                        'Content-Type': 'application/json',
                    },
                    next: {revalidate: 0}
                });

            const responseData: any = await res.json();
            this.setStorage(responseData);
            return {data: responseData, status: res.status};
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    parseBody(body: any) {
        const parsedStorage = this.parseStorage();
        if (!body) return JSON.stringify(parsedStorage);
        return JSON.stringify({...body, ...parsedStorage});
    }

    setStorage(data: any) {
        if (Array.isArray(data)) return;

        Object.keys(data).map((key: string) => {
            if (!this.storageKeys.includes(key)) return;
            this.setLocalStorage(key, data[key]);
        });
    }

    setLocalStorage(key: string, data: any): void {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            let expireDate: Date = new Date();
            expireDate.setFullYear(expireDate.getFullYear() + 1);
            setCookie(key, data, cookieOptions(expireDate, 60 * 60 * 24 * 365));
        }
    }

    getLocaleStorage(key: string): any {
        try {
            const item: string | null = localStorage.getItem(key);
            if (!item) return false;
            return JSON.parse(item);
        } catch (e) {
            return getCookie(key);
        }
    }

    getToken(): string {
        const token: CookieValueTypes = getCookie('sanctumToken');
        if (!token) return '';
        return decodeURI(getCookie('sanctumToken')!.toString());
    }

    parseStorage(): any {
        let obj: any = {};
        try {
            this.storageKeys.map((key: string) => {
                let value: any = this.getLocaleStorage(key);
                if (!value) value = getCookie(key);
                if (!value) return;
                let o: any = {};
                o[key] = value;
                obj = {...obj, ...o};
            });
        }
        catch(e) {}
        return obj;
    }

    clearStorage(): void {
        try {
            localStorage.clear();
        }
        catch(e) {}
    }

    clearCookies() {
        try {
            const cookies = getCookies();
            console.log('clear cookies', cookies);

            Object.keys(cookies).map((key: string) => {
                if (!this.clearableCookies.includes(key)) return;
                deleteCookie(key, cookieOptions(new Date(), 0));
            });
        }
        catch(e) {}
    }
}

const apiClient: ApiClient = new ApiClient();
export default apiClient