import {roundPrice} from "../utils/roundPrice";
import {getCookie} from "cookies-next";
import spRegionService from "./swisspost/spRegionService";
import {createHash} from "crypto";

class TagManager {
  pageTags(data: any, page: string, category?: any, lineItems?: any) {
    this.remarketing(page, category, lineItems);
    this.page(data);
  }

  async checkoutTags(event: string, page: string, category?: any, lineItems?: any) {
    try {
      // @ts-ignore
      window.dataLayer?.push({
        event: event,
      });
    } catch (e) {
    }

    this.remarketing(page, category, lineItems);
    await this.customer();
  }

  async finishTags(customer: any, order: any, lineItems: any) {
    let voucherCode = '';
    const voucher = lineItems.find((lineItem: any) => lineItem.mode === 2);
    if (voucher) voucherCode = voucher.orderNumber;

    try {
      // @ts-ignore
      window.dataLayer?.push({
        event: 'shopwareGTM.orderCompleted',
        customerEmail: customer.email,
        currencyCode: 'CHF',
        // current order is included in count, hence > 1
        visitorExistingCustomer: customer.orderCount > 1,
        hashedEmail: createHash('sha256').update(customer.email).digest('hex'),
        ecommerce: {
          purchase: {
            actionField: {
              id: order.orderNumber,
              revenue: order.invoiceAmount,
              tax: order.invoiceAmount - order.invoiceAmountNet,
              shipping: order.invoiceShipping,
              action: 'purchase',
              coupon: voucherCode
            },
            products: lineItems.map((lineItem: any) => {
              return {
                id: lineItem.orderNumber,
                brand: lineItem.brand,
                name: lineItem.name,
                price: lineItem.price,
                quantity: lineItem.quantity
              }
            })
          }
        }
      });
    } catch (e) {
    }

    // await this.checkoutTags('shopwareGTM.orderCompleted', page, category, lineItems);
  }

  remarketing(page: string, category?: any, lineItems?: any) {
    let params = {ecomm_pagetype: page};

    if (category) params = {...params, ...{ecomm_pcat: category}}
    if (lineItems) {
      const prices = lineItems.map((lineItem: any) => +roundPrice(lineItem.price * (lineItem.quantity ?? 1)));
      const total: number = prices.reduce((a: number, b: number) => a + b, 0);

      params = {
        ...params, ...{
          ecomm_pname: lineItems.map((lineItem: any) => lineItem.name),
          ecomm_prodid: lineItems.map((lineItem: any) => lineItem.orderNumber),
          ecomm_pvalue: prices,
          ecomm_totalvalue: roundPrice(total)
        }
      };
    }

    try {
      // @ts-ignore
      window.dataLayer?.push({
        event: 'remarketingTriggered',
        google_tag_params: params
      });
    } catch (e) {
    }
  }

  addToCart(products: any, quantity: number = 1) {
    try {
      // @ts-ignore
      window.dataLayer?.push({
        event: 'gtmAddToCart',
        ecommerce: {
          currencyCode: 'CHF',
          add: {
            products: products.map((product: any) => {
              return {
                name: product.name,
                id: product.orderNumber,
                price: `${product.price}`,
                brand: product.brand,
                quantity
              }
            })
          }
        }
      });
    } catch (e) {
    }
  }

  pushCart(products: any) {
    try {
      // @ts-ignore
      window.dataLayer?.push({
        event: 'gtmCart',
        ecommerce: {
          currencyCode: 'CHF',
          add: {
            cart: products.map((product: any) => {
              return {
                name: product.name,
                id: product.orderNumber,
                price: `${product.price}`,
                brand: product.brand,
                quantity: product.quantity
              }
            })
          }
        }
      });
    } catch (e) {
    }
  }

  removeFromCart(product: any, quantity?: number) {
    try {
      // @ts-ignore
      window.dataLayer?.push({
        event: 'gtmRemoveFromCart',
        ecommerce: {
          currencyCode: 'CHF',
          remove: {
            products: [
              {
                name: product.name,
                id: product.id,
                price: `${product.price}`,
                brand: product.brand,
                quantity: quantity ?? product.quantity
              }
            ]
          }
        }
      });
    } catch (e) {
    }
  }

  register() {
    try {
      // @ts-ignore
      window.dataLayer?.push({
        event: 'shopwareGTM.customerRegistered'
      });
    } catch (e) {
    }
  }

  login(push: boolean = true) {
    const customer = this.customerCookie();

    let data = {
      visitorExistingCustomer: customer ? 'Yes' : 'No',
      visitorLifetimeValue: customer ? customer.orderTotal : 0,
      visitorLoginState: customer ? 'LOGGED IN' : 'Logged Out',
      visitorType: customer ? customer.customerGroup : 'NOT LOGGED IN'
    };

    if (customer) data = {...data, ...{visitorId: customer.id}};

    if (push) {
      try {
        // @ts-ignore
        window.dataLayer?.push(data);
      } catch (e) {
      }
    }

    return data;
  }

  async customer() {
    const customer = this.customerCookie();
    if (!customer) return;

    const region = await spRegionService.getRegion(customer.zipCode, customer.city);

    try {
      // @ts-ignore
      window.dataLayer?.push({
        customerData: {
          salutation: customer.salutation,
          firstName: customer.firstName,
          lastName: customer.lastName,
          street: customer.street,
          zipCode: customer.zipCode,
          city: customer.city,
          region: region,
          country: customer.countryIso
        }
      });
    } catch (e) {
    }
  }

  customerCookie() {
    const cookie: any = getCookie('customer');
    try {
      return cookie ? JSON.parse(cookie) : null;
    } catch (e: any) {
      return null;
    }
  }

  page(data: any) {
    const params = {
      pageAttributes: 1,
      pageCategory: data.category ?? '',
      pageCategoryID: data.categoryId ?? '',
      pageCountryCode: data.countryCode ?? '',
      pageLanguageCode: data.languageCode ?? '',
      pageSubCategory: data.subCategory ?? '',
      pageSubCategoryID: data.subCategoryId ?? '',
      pageTestVariation: 1,
      pageTitle: data.pageTitle ?? '',
      pageValue: 1,
      pageVersion: 1,
      productCategory: data.productCategory ?? '',
      productCategoryPath: data.productCategoryPath ?? '',
      productColor: data.productColor ?? '',
      productCurrency: 'CHF',
      productEAN: data.ean ?? '',
      productID: data.productId ?? '',
      productName: data.productName ?? '',
      productPrice: data.productPrice ?? '',
      productRealColor: data.productColor ?? '',
      productSku: data.productOrderNumber ?? '',
      productStyleID: '',
      siteSearchTerm: data.siteSearchTerm ?? '',
      visitorDemographicInfo: '',
      visitorExistingCustomer: '',
      visitorId: '',
      visitorLifetimeValue: '',
      visitorLoginState: '',
      visitorSocialConnections: '',
      visitorType: '',
    }

    try {
      // @ts-ignore
      window.dataLayer?.push(params);
    } catch (e) {
    }
  }

  getBreadcrumb(breadcrumbs: any) {
    const b = breadcrumbs.map((breadcrumb: any) => breadcrumb.name);
    return b.join(' | ');
  }

  getCategoryPath(breadcrumbs: any) {
    const b = breadcrumbs.map((breadcrumb: any) => breadcrumb.name);
    return b.join('/');
  }

  detailPage(product: any) {
    try {
      // @ts-ignore
      window.dataLayer?.push({
        ecommerce: {
          "currencyCode": "CHF",
          "detail": {
            "products": [
              {
                "name": product.name,
                "id": product.orderNumber,
                "price": parseFloat(roundPrice(product.pseudoPrice)),
                "brand": product.brand,
                "category": product.category
              }
            ]
          },
          "impressions": []
        }
      });
    } catch (e) {
    }
  }

  checkoutPage(product: any) {
    try {
      // @ts-ignore
      window.dataLayer?.push({
        ecommerce: {
          "currencyCode": "CHF",
          "detail": {
            "products": [
              {
                "name": product.name,
                "id": product.orderNumber,
                "price": parseFloat(roundPrice(product.pseudoPrice)),
                "brand": product.brand,
                "category": product.category
              }
            ]
          },
          "impressions": []
        }
      });
    } catch (e) {
    }
  }
}

const tagManager = new TagManager();
export default tagManager
